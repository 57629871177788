@import "/src/lib/public.module";
.card-body {
  grid-column: span 2 / auto;
  border-radius: 8px;
  background-color: rgb(40, 43, 47);
  overflow: hidden;
  position: relative;
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  box-shadow: $myBoxShadow;
  cursor: pointer;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s;
  }
  &:hover {
    box-shadow: 0 0 10px rgba(206, 206, 206, 0.3);
  }
  &:hover .overlay {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .card-radio {
    position: absolute;
    left: 6px;
    top: 6px;
  }
  .card-radio-selfIcon {
    position: absolute;
    left: 6px;
    top: 6px;
    z-index: 1;
  }
  .card-delete {
    position: absolute;
    z-index: 1;
    right: 6px;
    top: 6px;
  }
  .video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
  .input-text-container {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30px;
    width: 100%;
    padding-bottom: 4px;
    .input-icon-describe {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .input-text-icon {
        cursor: pointer;
        margin: 6px;
        color: #ffffff;
        font-size: 20px;
      }
      .input-text-describe {
        cursor: pointer;
        color: #ffffff;
        font-size: 20px;
      }
    }
  }
  .input-text {
    border-radius: 0;
  }
}

.wrap {
  position: relative;

  .modal-content {
    height: 100%;
    padding: 0 !important;
  }
  .modal-body {
    display: flex;
    height: 100% !important;
    border-radius: 10px !important;
    overflow: hidden;
  }
  .icon-close {
    position: absolute;
    top: 0px;
    right: -100px;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 50px;
    height: 50px;
    font-size: 30px;
    color: rgb(255, 255, 255);
    background-color: rgb(126, 126, 126);
    border-radius: 50%;
  }
  .icon-close:hover {
    background-color: rgb(94, 94, 94);
  }
}
