@import "/src/lib/public.module";

.root {
  height: 100vh;
  background-image: url("../../assets/image/logo_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;

  .login {
    @include show-form-top;
    animation: $showFormTopAnimation;
    padding: 30px 30px;
    width: 300px;
    height: 250px;
    background-color: rgba(255, 255, 255, 0.3);
    margin-top: 300px;

    .title {
      font-size: 30px;
      color: rgb(255, 255, 255);
    }
    .btn {
      width: 100%;
      color: rgb(255, 255, 255);
      background-color: rgb(64, 150, 255);
      border-color: rgb(64, 150, 255);
    }
  }
}
