@import "/src/lib/public.module";
.text-content {
  margin: auto;
  padding: 10px;
  width: 1200px;
  border-radius: 10px;
  box-shadow: $myBoxShadow;

  @include enter-from-left;
  animation: $enterFromLeftAnimation;

  .name {
    font-size: 20px;
  }
  .name::placeholder {
    font-size: 20px;
  }

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .input-textarea {
    min-height: 290px !important;
  }
  .button-content {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 20px;
  }
}
.border-night {
  border: $myBorder;
}
.word-color-night {
  color: $myWordColor;
}
