@import "/src/lib/public.module";
.video-list {
  padding: 10px;
  min-height: 70vh;
  border-radius: 6px;

  @include enter-from-left;
  animation: $enterFromLeftAnimation;
  .video-container {
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(5, 1fr);
    .video-content {
      width: 300px;
      margin-top: 10px;
      padding-bottom: 10px;
      .video-name {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        text-align: center;
        .input {
          height: 27px;
          font-size: 20px;
          text-align: center;
        }
        .video-name-text {
          width: 90%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .edit-icon {
          display: none;
          position: absolute;
          right: 2px;
        }
      }
    }
    .video-content:hover {
      margin-top: 5px;
      border-bottom: 3px solid rgb(166, 97, 255);
      .edit-icon {
        display: block;
      }
    }
  }
}

.check-box-group {
  display: flex;
  flex-direction: column;
}
.download-item {
  height: 40px;
}

.text-color-night {
  color: $myWordColor;
}
