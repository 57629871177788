@import "/src/lib/public.module";
.card-content {
  margin: auto;
  margin-top: 0;
  width: 80%;
  box-shadow: $myBoxShadow;

  .card-body {
    max-height: 650px;
    overflow: auto;
  }

  .card-item {
    .delete {
      color: rgb(255, 0, 0);
    }
    .delete:hover {
      cursor: pointer;
    }
    .delete:active {
      color: rgb(255, 255, 255);
    }
    .time-new {
      width: 200px;
    }
    .time-new-err {
      input {
        color: rgb(255, 0, 0) !important;
      }
    }
  }
  .add-button {
    margin: auto;
    width: 20%;
    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 30px;
    }
    .button:hover {
      color: rgb(255, 255, 255);
      border-color: rgb(255, 255, 255);
    }
  }
}

.card-item-night {
  background-color: rgb(14, 14, 14);
}
.card-item-light {
  background-color: rgb(230, 230, 230);
}
.button-bgc-light {
  color: rgb(0, 0, 0);
  background-color: rgb(230, 230, 230);
}

:global {
  .ant-picker-footer {
    button {
      background-color: rgb(117, 117, 117);
    }
  }
}
