@import "/src/lib/public.module";
.root {
  margin: 0 100px;

  @include enter-from-left;
  animation: $enterFromLeftAnimation;
  .upload {
    padding: 10px;
    border-radius: 10px;
    box-shadow: $myBoxShadow;
    .file {
      margin: 0 auto;
      padding: 0 0 20px 0;
      width: 100%;
      .dragger {
        .upload-hint {
          color: rgb(124, 131, 156);
        }
      }
    }
    .divider {
      padding: 0;
      margin: 10px 0;
    }

    .btnAll {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      .name {
        width: 90%;
      }

      .btn {
        display: flex;
        gap: 20px;
      }
    }
  }
}
.text-color-night {
  color: $myWordColor !important;
}
