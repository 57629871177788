// 动画
@mixin enter-from-left {
  @keyframes enter-from-left {
    from {
      transform: translateX(-50px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

@mixin box-show {
  @keyframes box-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

@mixin show-form-top {
  @keyframes show-form-top {
    from {
      margin-top: 0px;
    }
    to {
      margin-top: 300px;
    }
  }
}

$enterFromLeftAnimation: enter-from-left 0.3s;
$boxShowAnimation: box-show 0.3s;
$showFormTopAnimation: show-form-top 1s;

// 非antd组件
// 暗夜
$myBoxColor: rgb(29, 30, 35);
$myWordColor: rgb(255, 255, 255);
$mySelectOPtionBgcColor: rgb(40, 43, 47);

// 光照
$myBoxColorLight: rgb(255, 255, 255);
$myWordColorLight: rgb(0, 0, 0);
$mySelectOPtionBgColorLight: rgb(255, 255, 255);

// 共有
$myBorder: 1px solid rgb(92, 92, 92);
$myBoxShadow: 0 0 15px rgb(104, 104, 104);
