@import "/src/lib/public.module";

.content {
  @include enter-from-left;
  animation: $enterFromLeftAnimation;

  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    height: 5vh;
    width: 100%;
    padding-right: 240px;

    .button-back {
      position: absolute;
      left: 60px;
      font-size: 30px;
    }
    .button-back:hover {
      cursor: pointer;
    }
  }

  .time-card {
    display: flex;
    margin-top: 30px;
    .side {
      display: flex;
      flex-direction: column;
      align-items: center;
      .file {
        height: 700px;
        margin-bottom: 50px;
        .upload-hint {
          color: rgb(124, 131, 156);
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}

.text-color-light {
  color: $myWordColorLight !important;
}
.text-color-night {
  color: $myWordColor !important;
}

.down-subtitle-light {
  border: 1px solid rgb(217, 217, 217);
  .down-subtitle-btn-light {
    border-right: 1px solid rgb(217, 217, 217) !important;
  }
}

.down-subtitle-black {
  border: 1px solid rgb(66, 66, 66);
  .down-subtitle-btn-black {
    border-right: 1px solid rgb(66, 66, 66) !important;
  }
}
