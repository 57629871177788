.btn-state-wait {
  color: rgb(180, 180, 180);
}

.btn-state-isdone {
  color: rgb(18, 81, 255);
}

.btn-state-err {
  color: red;
}
