/* .quill, */
.ql-container,
.ql-snow {
  height: 100% !important;
  min-height: 100% !important;
  font-size: 16px;
  border-radius: 10px !important;
  border: 0 !important;
}
.ql-editor,.ql-blank {
  padding: 4px 11px;
  width: 100%;
  height: 100% !important;
  min-height: 100% !important;
  /* overflow-y:auto;
  resize: vertical; */
}
.ql-editor.ql-blank::before {
  left: 11px;
  font-style: normal;
  color: rgb(117,117,117);
}

.rich {
  position: relative;
  width: 100%;
}

.black {
  color: rgb(255, 255, 255);
}
