@import "/src/lib/public.module";
.ro {
  margin: 0 100px;
  padding-bottom: 20px;
  @include enter-from-left;
  animation: $enterFromLeftAnimation;
  .root {
    border-radius: 6px;
    padding: 10px;
    box-shadow: $myBoxShadow;
    .upload {
      width: 100%;
      .file {
        display: inline-block;
        width: 100%;
        border-color: red !important;
        .upload-hint {
          color: rgb(124, 131, 156);
        }
      }
    }
    .image-container {
      // display: grid;
      gap: 16px;
      // grid-template-columns: repeat(6, 1fr);
      display: flex;
      margin-bottom: 10px;
      padding: 20px;
      overflow: hidden;
      overflow-x: auto;
      .image-item-container {
        display: flex;
        flex-direction: column;
        flex-basis: 216px;
        flex-shrink: 0;
        white-space: nowrap;
        .image-item {
          padding: 5px;
          border-radius: 10px;
          .image {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
            object-position: center center;
          }
          .image-mask {
            border-radius: 10px;
          }
        }
        .image-item-checked {
          background-color: rgb(200, 255, 0);
          // background-color: rgb(0, 247, 205);
        }
        .image-number {
          text-align: center;
          font-size: 20px;
        }
        .image-number-err {
          font-weight: 900;
          color: rgb(255, 0, 0) !important;
        }
      }
    }
    .divider {
      padding: 0;
      margin: 0;
    }
    .footer {
      height: 50px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      .btnAll {
        padding-right: 10px;
        display: flex;
        gap: 20px;
      }
      .gennerateProgesss {
        padding: 0 50px;
        height: 100px;
      }
    }
    .generateTip {
      display: flex;
      justify-content: flex-end;
      padding-right: 10px;
      color: rgb(255, 0, 0);
    }
  }

  .headerInputContent {
    margin-top: 20px;
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    box-shadow: $myBoxShadow;
    .videoName {
      font-size: 22px;
      border: 0;
      border-radius: 0;
    }
    .videoName::placeholder {
      font-size: 22px;
    }
    .btnInputAll {
      margin-top: px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }
  }
  .footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
    .textWronTip {
      color: rgb(255, 0, 0);
    }
    .btn {
      width: 100px;
    }
  }
  .foot-tip {
    margin-top: 12px;
    padding-left: 31px;
    width: 80%;
    font-size: 16px;
  }
}
.text-color-night {
  color: $myWordColor;
}

.foot-tip-light {
  color: rgb(88, 88, 88);
}

.foot-tip-night {
  color: rgb(255, 0, 0);
}

:global {
  // 图标大小
  .ant-upload-list-item {
    svg {
      font-size: larger !important;
    }
  }
  // 加载时图标和字体的颜色
  .ant-upload-list-item-uploading {
    .anticon-spin {
      svg {
        color: rgb(22, 119, 255) !important;
      }
    }
    .ant-upload-list-item-name {
      color: rgb(22, 119, 255) !important;
    }
  }
  // 完成时图标和字体的颜色
  .ant-upload-list-item-done {
    .anticon-paper-clip {
      svg {
        color: rgb(82, 196, 26) !important;
        // font-size: larger !important;
      }
    }
    .ant-upload-list-item-name {
      color: rgb(82, 196, 26) !important;
    }
  }
}
