@import "/src/lib/public.module";
.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include enter-from-left;
  animation: $enterFromLeftAnimation;
  .body {
    padding: 20px;
    width: 80%;
    border-radius: 10px;
    box-shadow: $myBoxShadow;
    .videoName {
      font-size: 22px;
      border: 0;
      border-radius: 0;
    }
    .videoName::placeholder {
      font-size: 22px;
    }
    .btnAll {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-top: 20px;
      .btnAllSon {
        display: flex;
        gap: 20px;
        .formatButton {
          background-color: red !important;
        }
      }
    }
    .textWronTip {
      margin-top: 5px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: red;
    }
  }
  .foot-tip {
    margin-top: 12px;
    padding-left: 31px;
    width: 80%;
    font-size: 16px;
  }
}

.foot-tip-light {
  color: rgb(88, 88, 88);
}

.foot-tip-night {
  color: rgb(255, 0, 0);
}
