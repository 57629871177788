.root {
  .side {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    .sideHeader {
      display: flex;
      justify-content: center;
      margin: 20px 0;
      padding: 0;
      width: 100%;
      border-radius: 10px;
      font-size: 30px;
      color: rgb(255, 255, 255);
      .imgTitle {
        height: 100%;
      }
    }
    .swich-light {
      background-color: rgb(230, 230, 230);
    }
    .side-content {
      margin-top: 20px;
    }
  }
  .content {
    overflow: auto;
    .contentBody {
      padding: 24px 16px;
      height: 100vh;
      box-sizing: border-box;
    }
  }
}
