.upload {
  width: 100%;
  .file {
    display: inline-block;
    width: 100%;
    border-color: red !important;
    .upload-hint {
      color: rgb(124, 131, 156);
    }
  }
}
