@import "/src/lib/public.module";
.root {
  width: 100%;
  .title {
    margin: 20px 0;
    padding: 0 20px;
    font-size: 30px;
  }
  .recommendContent {
    height: 436px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: $myBoxShadow;
  }
  .content {
    margin-bottom: 10px;

    .videoAll {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(10, 1fr);
    }
    .btn {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
      padding-right: 20px;
      width: 100%;
      .btnSon {
        display: flex;
        justify-content: space-between;
        gap: 16px;
      }
    }
  }
}

.word-color-night {
  color: $myWordColor;
}
