@import "/src/lib/public.module";

.content {
  .icon {
    font-size: 30px;
    color: black !important;
  }

  .icon-black {
    font-size: 30px;
    color: rgb(136, 136, 136) !important;
  }
}

body {
  position: relative !important;
  .siderContent {
    position: fixed !important;
    right: 0;
    top: 300px;
    // 包含动画混合宏
    @include box-show;
    // 应用动画效果
    animation: $boxShowAnimation;
  }
}
