@import "/src/lib/public.module";
.root {
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 80%;

  @include enter-from-left;
  animation: $enterFromLeftAnimation;
  .headerInputContent {
    padding: 20px;
    width: 100%;
    border-radius: 10px;
    box-shadow: $myBoxShadow;
    .videoName {
      font-size: 22px;
      border: 0;
      border-radius: 0;
    }
    .videoName::placeholder {
      font-size: 22px;
    }
    .btnInputAll {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      .showCount {
        margin-left: 10px;
        color: rgb(117, 117, 117);
      }
      .btn {
        display: flex;
        gap: 20px;
      }
    }
  }
}
