@import "/src/lib/public.module";

.body {
  margin: auto;
  width: 80%;
  box-shadow: $myBoxShadow;
  .input {
    width: 95%;
  }
  .content {
    margin: 0 auto;
    border-radius: 10px;
    .table {
      a {
        color: $myWordColor;
      }
    }
  }
}
