@import "/src/lib/public.module";

.task {
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.checkboxAll {
  display: flex;
  align-items: center;
  height: 20px;
}

.btn {
  border: 1px solid rgb(0, 0, 0);
}

.btn-black {
  border: 1px solid rgb(255, 255, 255);
}

.btn-ok {
  border: 1px solid rgb(22, 119, 255);
  background-color: rgb(22, 119, 255);
}

.show-btn-opacity {
  display: none;
}

.video-name-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn-state {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
}

.btn-state-wait {
  color: rgb(180, 180, 180);
}

.btn-state-isdone {
  color: rgb(18, 81, 255);
}

.btn-state-err {
  color: red;
}

.btn-generate {
  color: rgb(29, 212, 29);
}
.btn-generate:hover {
  color: rgb(29, 212, 29) !important;
}
.btn-generate-light:active {
  background-color: rgb(240, 240, 240) !important;
}
